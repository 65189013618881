import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


const ImgWithDog = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "author/beverly-hubbard-author-outside-with-dog-orlando-florida.jpg" }) {
          childImageSharp {
            fixed(width: 280) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => <Img fixed={data.placeholderImage.childImageSharp.fixed} />}
  />
)
export default ImgWithDog
