// eslint-disable-next-line
import React from "react"
import styled from '@emotion/styled'

// Components
import MediaQuery from 'react-responsive'
import Layout from "../components/layout"
import Masthead from "../components/organisms/masthead"
import Paragraph1 from "../components/atoms/paragraph-1";
import Header1 from "../components/atoms/header-1";
import ContentOverflow from "../components/organisms/content-overflow";
import Footer from "../components/organisms/footer";
import Quote from "../components/atoms/quote";

// Images
import ImgMastheadAuthor from "../components/images/_masthead/img-masthead-author"
import ImgWithBook from '../components/images/author/img-with-book.js'
import ImgWithDog from '../components/images/author/img-with-dog.js'
import ImgWithHusband from '../components/images/author/img-with-husband.js'
import ImgFooterAuthor from "../components/images/_footer/img-footer-author";
import SEO from "../components/organisms/seo";

// Styles
const ContainerPhotos = styled.section`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  margin: 30px 0 60px 0; 
  > div {
    margin-bottom: 30px;
  }
  > div:last-child {
    margin-bottom: 0;
  }
`

// Date
const authorData = {
  title: 'About the Author',
  text: [
    'It was purely by God’s grace that Beverly Hubbard grew up saturated with the Bible.  Every evening after supper her mother would take out the big Eggemeier’s Bible Story Book with its wonderful illustrations and read to her and her younger siblings.  She remembers once when she was maybe four or five, she cried because they finished the book.  “That’s all right,” her mother said, “we’ll just start over.”',
    'Beverly’s parents’ passion was for everyone to know the message of the Bible so that they could know God through faith in Jesus Christ.  When her parents went to Brazil as missionaries, she and her siblings were educated in mission boarding schools.  It was during her childhood years that she came to understand that God was not only her parents’ God, but also her God, and He is powerful and trustworthy.  She learned that what He says in the Bible is true, and that it is for her personally.  Her faith increased as God watched over her through all kinds of circumstances.',
    'Later she graduated from Seattle Pacific University, where she majored in English and got a teaching certificate.  Returning to Brazil, she taught in a school for missionaries’ children.  She and her husband served there together for thirteen years.  More recently, they have worked in Christian schools in Central Florida and are currently active in their church.  They have four children and ten grandchildren.',
  ]
}

const writingData = {
  title: 'About the Writing',
  text: [
    'When Beverly and her husband returned to the States from years of missionary work in Brazil, they were astounded to discover that the Bible had gone out of style.  People were not familiar with it, not even church people.  To come back and find things so different from what they used to be was a shock.  What could have caused the change?  The Bible had been removed from society so gradually and quietly that people didn’t even know what they were missing.',
    'But they need to know!  Without the Bible, people can’t know who God is and that their very existence depends on Him.  They can’t know the consequences of trying to live without Him or God’s plan to rescue them from an eternal destination without Him.  The question that haunted Beverly was: What can I do about it?',
    'She was given the opportunity to teach a week-night adult class using the Firm Foundations curriculum written by Trevor McIlwain and Nancy Everson.  The course shows how God has revealed His great plan of redemption through the stories of the Old Testament up through the time of Jesus Christ.  It was a six months’ commitment, and she taught it to five different groups of people.  Many of them—professional people—didn’t know who Abraham was, or Samuel, or David or why their stories are important to us.  At the end of the course they would say, “I never heard this before!  I never understood why Jesus had to die.  I never realized I was trusting in other things for my salvation.”',
    'How can I tell more people? Beverly wondered.  There needs to be a book, not a teaching manual, but a storybook that an individual can read through, that communicates the message of each story in an engaging enough way that will keep the reader turning pages.  Could she write a book like that?  What would be the hook that would grab people’s interest?  She remembered a favorite author, Howard Hendricks, from her mission training days.  In his book on the art of teaching was a section on the art of questioning—using a question to arouse curiosity, stimulate thinking and lead to the answer.  Yes, she could start each story with a question.',
    'But write a book to summarize the Bible?  Why not just encourage people to read the Bible?  That would be wonderful if they would do it.  But here’s the thing.  They’ve never had a compelling reason to pick one up.  They need to be introduced.  What if she described her book as the program notes for a great drama?  It would explain the themes, summarize the action and introduce the characters to open the way for exploring the greatest story ever told—God’s message to the world.',
    'She didn’t have this all thought out yet when she started writing one story at a time for her church’s monthly newsletter.  Then that opportunity ended, but the last class group she taught just kept going—for years.  During that time she began working in earnest on the stories with the intention of finishing the book and one day publishing it.  Each week she would bring a finished story for the group to critique.  What an encouragement their input was!  They are an example of the many folks God had in place to help make The Story of the Bible a reality.',
  ]
}

const AuthorPage = () => (
  <Layout>

    <SEO title="Author | Beverly Hubbard" keywords={[`beverly hubbard`, `author`, `the story of the bible`, `book writer`, `christian book author`, `author`]} />

    <Masthead
      image={<ImgMastheadAuthor/>}
    />

    <ContentOverflow topOverflow='850px'>

      <MediaQuery query="(min-width: 500px)">
        <Quote align='center'>"Blessed are those whose strength is in you,<br/>whose hearts are set on pilgrimage."<br/>Psalm 84:5</Quote>
      </MediaQuery>
      <MediaQuery query="(max-width: 499px)">
        <Quote align='center'>"Blessed are those whose<br/>strength is in you, whose<br/>hearts are set on pilgrimage."<br/>Psalm 84:5</Quote>
      </MediaQuery>

      <Header1 align='left'>{authorData.title}</Header1>
      {authorData.text.length > 0 && 
        authorData.text.map((text, i) => (
          <Paragraph1 key={i}>{text}</Paragraph1>
        ))
      }

      <ContainerPhotos>
        <ImgWithBook/>
        <ImgWithDog/>
        <ImgWithHusband/>
      </ContainerPhotos>

      <Header1 align='left'>{writingData.title}</Header1>
      {writingData.text.length > 0 && 
        writingData.text.map((text, i) => (
          <Paragraph1 key={i}>{text}</Paragraph1>
        ))
      }
      
    </ContentOverflow>

    <Footer
      image={<ImgFooterAuthor/>}
    />

  </Layout>
)

export default AuthorPage
